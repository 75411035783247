var headroom;

// Load background images
jQuery(".load-bg-img").each(function (i, obj) {
  loadBgImage(jQuery(this));
});

function loadBgImage(element) {
  var large_img = element.attr("data-bg-large");
  var small_img = element.attr("data-bg-small");

  var bgImg = new Image();
  bgImg.onload = function () {
    element.css("background-image", "url(" + bgImg.src + ")");
    element.addClass("loaded");
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = element.attr("data-bg");
  } else if (jQuery(document).width() > 400) {
    bgImg.src = element.attr("data-bg-small");
  } else {
    bgImg.src = element.attr("data-bg-tiny");
  }
}

jQuery(document).ready(function () {
  jQuery(".answer.collapse").on("hide.bs.collapse", function () {
    jQuery(this).parent().find("h3").removeClass("showing");
  });

  jQuery(".answer.collapse").on("show.bs.collapse	", function () {
    jQuery(this).parent().find("h3").addClass("showing");
  });

  jQuery(".nav.collapse").on("hide.bs.collapse", function () {
    jQuery(".navbar-container").removeClass("show");
    jQuery(".navbar-toggler").removeClass("show");
    jQuery("html").removeClass("show-nav");
    //jQuery("body").removeClass("no-scroll");
    setTimeout(function () {
      jQuery(".navbar-container").removeClass("showing");
      jQuery(".navbar-toggler").removeClass("showing");
      jQuery("html").removeClass("showing-nav");
    }, 100);
  });

  jQuery(".nav.collapse").on("show.bs.collapse	", function () {
    jQuery(".navbar-container").addClass("showing");
    jQuery(".navbar-toggler").addClass("showing");
    jQuery("html").addClass("showing-nav");
    //jQuery("body").addClass("no-scroll");

    setTimeout(function () {
      jQuery(".navbar-container").addClass("show");
      jQuery(".navbar-toggler").addClass("show");
      jQuery("html").addClass("show-nav");
    }, 100);
  });

  jQuery(".the-search-form.collapse").on("show.bs.collapse	", function () {
    setTimeout(function () {
      jQuery(".the-search-form input.search-field").focus();
    }, 300);
  });
});


/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        jQuery(
          ".marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p"
        ).each(function () {
          var string = jQuery(this).html();
          string = string.replace(/ ([^ ]*)$/, "&nbsp;$1");
          jQuery(this).html(string);
        });

        var viewport_width = jQuery(document).width();

        var offset = 0;
        if (vars.show_top_navigation) {
          offset = 40;
        }
        if (viewport_width < 992) {
          // Check to see if we're showing an announcement.
          if (vars.show_top_navigation && jQuery('html').hasClass('show-announcement')) {
            offset = 40;
          }
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function getScrollTop() {
  if (typeof pageYOffset !== "undefined") {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
}

function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}


jQuery(document).ready(function () {
  jQuery("html").addClass("js");
});



/* ================================
 * Map locations
 * ============================= */
jQuery(document).ready(function () {


  jQuery(document).on("facetwp-loaded", function () {

    var in_use = false;

    // see if any facets are in use
    (jQuery).each(FWP.facets, function (name, val) {
      if (val.length > 0) {
        in_use = true;
      }
    });


    if (!in_use) {
      jQuery(".reset-button").addClass("disabled");
      jQuery(".reset-button").attr("disabled", "disabled");
    } else {
      jQuery(".reset-button").removeClass("disabled");
      jQuery(".reset-button").removeAttr("disabled", "disabled");
    }


    jQuery(".facetwp-template .load-bg-img").each(function (i, obj) {
      loadBgImage(jQuery(this));
    });

    jQuery(".facetwp-template").removeClass("loading");

  });



});

jQuery(document).ready(function () {
  jQuery(document).on("facetwp-refresh", function () {
    jQuery(".facetwp-template").addClass("loading");
  });
});

// Accordian stuff
jQuery(document).ready(function () {
  // the current open accordion will not be able to close itself
  jQuery('.accordion button[data-toggle="collapse"]').on("click", function (e) {
    if (!jQuery(this).hasClass("collapsed")) {
      e.stopPropagation();
    }
  });
});

function createCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}

/* ================================
 * Cookie notice
 * ============================= */
jQuery(document).ready(function () {
  var allow_cookies = readCookie("allow_cookies");
  if (allow_cookies !== "yes") {
    jQuery(".cookie-notice").addClass("display");
    jQuery(".cookie-notice").addClass("inplace");
  } else {
    console.log("Cookies allowed.");
  }

  jQuery("#allow-cookies").click(function (event) {
    createCookie("allow_cookies", "yes", 7);

    setTimeout(function () {
      jQuery(".cookie-notice").removeClass("display");
    }, 200);
    jQuery(".cookie-notice").removeClass("inplace");

    return false;
  });
});

// Tooltips
jQuery(document).ready(function () {
  jQuery(function () {
    jQuery('[data-toggle="tooltip"]').tooltip();
  });
});

jQuery(document).ready(function () {
  jQuery(".popover-info").popover();
});
